import axios from "@/plugins/axios";
import {AxiosResponse} from "axios";

const resource = 'api/v1/brand_reference';

export default {
    geBrandReference(type_equipment: string): Promise<AxiosResponse<IServerResponse<IBrandReference[]>>> {
        return axios.get(resource, {params: {type: type_equipment}});
    },
}
